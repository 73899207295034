<template>
  <b-card no-body>
    <b-card-body>
      <referral-codes />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody,
} from 'bootstrap-vue'
import ReferralCodes from '@/views/apps/referral/referral-codes/ReferralCodes.vue'

export default {
  components: {
    ReferralCodes,
    BCard,
    BCardBody,
  },

  setup() {
    return {
    }
  },
}
</script>
<style scoped>

</style>
